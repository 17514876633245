import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';
import { AuthService } from '../../shared/services/auth.service';
import { Page } from '../../shared/models/page.model';
import { User } from '../../shared/models/user.model';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  public currentUser: User | undefined = undefined;
  public version: string = 'v1.2.4';
  public pages: Page[] = [
    { icon: 'home', label: 'Dashboard', path: 'app/dashboard', selected: false },
  ];

  constructor(private _auth: AuthService, private router: Router) {
    // this.currentUser = this._auth.currentUser.value;
    //
    // this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: any) => {
    //   let _url = event.urlAfterRedirects;
    //
    //   this.pages.filter(page => page.selected == true).forEach(page => page.selected = false);
    //
    //
    //   let el = this.pages.find(page => { return _url.includes(page.path?.split('/').pop()) });
    //   if (el) el.selected = true;
    //});
  }

  ngOnInit(): void { }


}
