import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable, ReplaySubject} from "rxjs";
import {FormattedResponse} from "../models/formatted-response";
import {User} from "../models/user.model";
import {environment} from "../../environments/environment";
import {SelectOptions} from "../models/configuration.model";

@Injectable({
  providedIn: 'root'
})
export class UtilitiesService {
  private stallRedirect: ReplaySubject<string> = new ReplaySubject<string>();

  constructor(private http: HttpClient) { }

  getRoles(): Observable<FormattedResponse<SelectOptions[]>> {
    return this.http.get<FormattedResponse<SelectOptions[]>>(`${environment.api}/roles`);
  }

  setStallRedirect(value: string){
    this.stallRedirect.next(value);
  }

  getStallRedirect(): Observable<string>{
    return this.stallRedirect.asObservable();
  }
}
