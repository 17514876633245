// Angular
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import {
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpEvent,
  HttpResponse,
  HttpErrorResponse,
  HttpContextToken, HttpContext
} from '@angular/common/http';
// RxJS
import { map, Observable, of, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { LoaderService } from './loader.service';
import { Router } from '@angular/router';
import { HttpContextConfig } from '../models/http-context-config';
import { NzNotificationComponent, NzNotificationService } from 'ng-zorro-antd/notification';
import { isPlatformBrowser } from '@angular/common';

/**
 * More information there => https://medium.com/@MetonymyQT/angular-http-interceptors-what-are-they-and-how-to-use-them-52e060321088
 */

export const CONFIG = new HttpContextToken<HttpContextConfig>(() => new HttpContextConfig())

@Injectable({
  providedIn: 'root'
})

export class InterceptorService implements HttpInterceptor {
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private loaderService: LoaderService,
    private router: Router,
    private notificationService: NzNotificationService,
  ) {
  }


  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let storageData: any;

    if (isPlatformBrowser(this.platformId)) {
      storageData = localStorage.getItem('access_token') || '{}';
    }

    // set withCredentials to true to send cookies
    request = request.clone({
      withCredentials: true
    });

    if (storageData) {
      request = request.clone({
        setHeaders: {
          'Authorization': `Bearer ${storageData}`,
          'ngrok-skip-browser-warning': "true",
        },
        withCredentials: true
      });
    }

    // check if the request have NO_SPINNER context
    if (request.context.get(CONFIG)) {
      let config = request.context.get(CONFIG);
      if (!config.noSpinner) {
        this.loaderService.setLoading(true, request.url);
      }
    }

    return next.handle(request).pipe(
      map<HttpEvent<any>, any>((evt: HttpEvent<any>) => {
        if (evt instanceof HttpResponse) {
          this.loaderService.setLoading(false, request.url);

          if (request.method != 'GET' && evt.body.showMessage) {
            this.notificationService.success('', evt.body.message);
          }
        }
        return evt;
      })
    )
      .pipe(tap((event) => { }),
        catchError((err) => {
          let config = request.context.get(CONFIG);
          this.loaderService.setLoading(false, request.url);

          if (config.isInitializer) {
            return of<any>(null);
          }

          if (err.status === 401) {
            this.router.navigate(['/auth/login']);
          }
          else if (new RegExp('[4,5]0[0-5]').test(err.status) && !err.error.customErrorMessage) {
            this.notificationService.error('Errore', 'Qualcosa è andato storto.');
          }
          else if (err.error.customErrorMessage) {
            this.notificationService.error('Errore', err.error.message);
          }

          return throwError(err.error);
        })
      )
  }
}
