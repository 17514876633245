import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrl: './modal.component.scss'
})
export class ModalComponent {
  @Input() isVisible: boolean = false;
  @Input() title: string = '';
  @Input() nzOkText: string = 'CONFERMA';
  @Input() type: string = '';
  @Input() nzTitle: any;
  @Input() nzContent: any;
  @Input() nzFooter: any;

  @Output() closedModal: EventEmitter<any> = new EventEmitter();

  public isOkLoading: boolean = false;

  showModal(): void {
    this.isVisible = true;
  }

  handleOk(): void {
    this.isOkLoading = true;
    setTimeout(() => {
      this.isVisible = false;
      this.isOkLoading = false;
    }, 3000);
  }

  handleCancel(): void {
    this.isVisible = false;
  }
}
