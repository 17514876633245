import { Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { BaseComponent } from '../base/base.component';
import { TableColumn } from './datatable.model';
import {ActionConfiguration, ButtonConfiguration, FilterConfiguration} from '../../shared/models/configuration.model';
import {FormControl, FormGroup} from "@angular/forms";
import {debounceTime} from "rxjs";

@Component({
  standalone: false,
  selector: 'app-datatable',
  templateUrl: './datatable.component.html',
  styleUrl: './datatable.component.scss',
  host: { ngSkipHydration: 'true' },
})

export class DatatableComponent extends BaseComponent {
  @Input() reloadData!: EventEmitter<void>;
  @Input() count: number = 0;
  @Input() total: number = 0;
  @Input() buttons: ButtonConfiguration[] = [];
  @Input() actions: ActionConfiguration[] = [];
  @Input() filters: FilterConfiguration[] = [];
  @Input() listOfColumn: TableColumn<any>[] = [];
  @Input() listOfData: any[] = [];
  @Input() config: any = null;

  pageIndex: number = 1;
  pageSize: number = 10;
  filters_form : FormGroup = new FormGroup<any>({});
  search: FormControl = new FormControl<string|null>(null);

  ngOnInit() {
    this.getData();

    //All'input nella barra di ricerca viene inviato l'evento al componente padre
    this.search.valueChanges.pipe(debounceTime(1000)).subscribe({
      next: () => {
        this.pageIndex = 1;
        this.getData();
      }
    })

    //al cambiamento di un filtro resetto la paginazione e richiamo l'api
    this.filters_form.valueChanges.pipe(debounceTime(500)).subscribe({
      next: () => {
        this.pageIndex = 1;
        this.getData();
      }
    })

    //Quando avvengono aggiunta/modifica/eliminazione dei dati nel componente padre
    this.reloadData.subscribe({
      next: () => {
        this.pageIndex = 1;
        this.getData();
      }
    })
  }

  /**
   * Chiama la funzione per popolare la tabella
   */
  private getData(){
    this.config.function({search: this.search.value, pageSize: this.pageSize, pageIndex: this.pageIndex, ...this.filters_form.value});
  }

  /**
   * Imposta il numero di elementi per pagina dal componente padre
   * @param changes
   */
  ngOnChanges(changes: any){
    if(changes.config){
      this.pageSize = changes.config.currentValue.pageSize;
    }

    if(changes.filters) {
      this.filters.forEach(item => {
        this.filters_form.addControl(item.name, new FormControl(null), {emitEvent: false});
      });
    }
  }

  /**
   * Al cambio di pagina
   */
  onPageIndexChange(index: number): void {
    this.pageIndex = index;
    this.getData();
  }

  /**
   * Al cambio di elementi per pagina
   */
  onPageSizeChange(size: number): void {
    this.pageSize = size;
    this.pageIndex = 1;

    this.getData();
  }
}
