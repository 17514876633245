<nz-modal
  [(nzVisible)]="isVisible"
  [nzTitle]="nzTitle"
  [nzOkText]="nzOkText"
  [nzOkLoading]="isOkLoading"
  [nzContent]="nzContent"
  [nzFooter]="nzFooter"
  (nzOnCancel)="handleCancel()"
  (nzOnOk)="handleOk()"
  (nzAfterClose)="closedModal.emit()">
</nz-modal>
