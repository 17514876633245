<section class="mb-3">
    <nz-breadcrumb>
        @for (route of routes; track route) {
        <nz-breadcrumb-item [ngClass]="{'disabled': route.disabled}">
            <a [routerLink]="route.path">
                @if (route.icon) {
                <span nz-icon [nzType]="route.icon"></span>
                }
                <span>{{ route.name }}</span>
            </a>
        </nz-breadcrumb-item>
        }
    </nz-breadcrumb>
</section>