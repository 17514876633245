import { Component, inject } from '@angular/core';
import { Location } from '@angular/common';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { AuthService } from '../../shared/services/auth.service';
import { Router } from '@angular/router';
import { FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-base',
  templateUrl: './base.component.html',
  styleUrl: './base.component.scss'
})
export class BaseComponent {
  /** Inject di tutti i servizi utili */
  public authService: AuthService = inject(AuthService);
  public formBuilder: FormBuilder = inject(FormBuilder);
  public location: Location = inject(Location);
  public modalService: NzModalService = inject(NzModalService);
  public notificationService: NzNotificationService = inject(NzNotificationService);
  public router: Router = inject(Router);

  /** Regex di controllo per validatori */
  public regex = {
    name: "^([a-zA-Z\xE0\xE8\xE9\xF9\xF2\xEC\x27\ s]?)+$",
    email: "[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}",
    password: "^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&])[A-Za-z\\d@$!%*?&]{8,}$",
    phone: "^\\s*(?:\\+?(\\d{1,3}))?[-. (]*(\\d{3})[-. )]*(\\d{3})[-. ]*(\\d{3,4})(?: *x(\\d+))?\\s*$",
    mobile: "^([+]39)?\\s?((313)|(32[0123456789])|(33[013456789])|(35[0123456789])|(34[0123456789])|(36[0368])|(37[0123456789])|(38[0389])|(39[0123]))[\\s-]?([\\d]{7})$",
    vatNumber: "([0-9]{11})",
    fiscal_code: "^[a-zA-Z]{6}[0-9]{2}[abcdehlmprstABCDEHLMPRST]{1}[0-9]{2}([a-zA-Z]{1}[0-9]{3})[a-zA-Z]{1}$",
    url: "https?:\\/\\/(www\\.)?[-a-zA-Z0-9@:%._\\+~#=]{1,256}\\.[a-zA-Z0-9()]{1,6}\\b([-a-zA-Z0-9()@:%_\\+.~#?&//=]*)",
    vatNumberFiscalCode: "^[a-zA-Z]{6}[0-9]{2}[abcdehlmprstABCDEHLMPRST]{1}[0-9]{2}([a-zA-Z]{1}[0-9]{3})[a-zA-Z]{1}$|([0-9]{11})"
  }

  goBack() {
    this.location.back();
  }
}
