<article class="d-flex flex-row  justify-content-between flex-wrap my-4 gap-3">

    <div class="col d-flex">
        @if(config.searchPlaceholder) {
            <section class="col-4">
                <nz-input-group nzSearch [nzAddOnAfter]="suffixIconButton">
                    <input [formControl]="search" type="text" nz-input [placeholder]="config.searchPlaceholder"/>
                </nz-input-group>
                <ng-template #suffixIconButton>
                    <button nz-button nzType="primary" nzSearch><span nz-icon nzType="search"></span></button>
                </ng-template>
            </section>
        }

        <div [formGroup]="filters_form" class="w-100">
            @for (filter of filters; track filter) {
                @switch(filter.type) {
                    @case ('select') {
                        <section [class]="filter.style">
                            <nz-select nzAllowClear [nzPlaceHolder]="filter.placeholder" class="w-100" [formControlName]="filter.name">
                                <nz-option *ngFor="let data of filter.data" [nzValue]="data.id" [nzLabel]="data.label"></nz-option>
                            </nz-select>
                        </section>
                    }
                }
            }
        </div>
    </div>

    @for (action of buttons; track action) {
        @switch(action.type) {
            @case ('button') {
                <button nz-button [nzBlock]="action.nzBlock" [nzType]="$any(action.nzType || 'default')" [nzDanger]="action.nzDanger"
                        (click)="action.function ? action.function() : null" [routerLink]="action.link ? action.link : null" [class]="action.class" [disabled]="action.disabled">
                    {{ action.label }}
                </button>
            }
        }
    }
</article>

<nz-table #sortTable
          [nzPageSize]="pageSize"
          [nzData]="listOfData"
          [nzShowPagination]="false"
          nzTableLayout="fixed"
          nzNoResult="Nessun dato disponibile">
    <thead>
        <tr>
            @for (column of listOfColumn; track column) {
                <th>
                    {{ column.title }}
                </th>
            }
        </tr>
    </thead>
    <tbody>
        @for (data of sortTable.data; track data) {
            <tr [ngClass]="{'striped-tr': $index % 2 !== 0}">
                @for (column of listOfColumn; track column) {
                    <td>{{ data[column.key] }}</td>
                }

                @if(actions.length > 0){
                    <td>
                        <div class="actions">
                            @for (action of actions; track action) {
                                <button nz-tooltip [nzTooltipTitle]="action.tooltip" nz-button [nzType]="action.nzType" (click)="action.function ? action.function(data) : null">
                                    @switch (action.type){
                                        @case('label'){
                                            <span [style]="action.style">{{action.label}}</span>
                                        }
                                        @case('icon'){
                                            <span nz-icon [nzType]="action.iconType!" nzTheme="outline"></span>
                                        }
                                    }
                                </button>
                            }
                        </div>
                    </td>
                }
            </tr>
        }
    </tbody>
</nz-table>

<nz-pagination
  class="d-flex justify-content-end pb-4 mt-3"
  nzShowSizeChanger
  [nzPageIndex]="pageIndex"
  [nzTotal]="total"
  [nzPageSize]="pageSize"
  [nzShowTotal]="rangeTemplate"
  (nzPageIndexChange)="onPageIndexChange($event)"
  (nzPageSizeChange)="onPageSizeChange($event)">
</nz-pagination>


<ng-template #rangeTemplate let-range="range" let-total>
  {{ range[0] }}-{{ range[1] }} di {{ total }}
</ng-template>
