import {Component, Input, OnInit} from '@angular/core';
import {AuthService} from "../../shared/services/auth.service";
import {NavigationEnd, Router} from "@angular/router";
import {UserModel} from "../../shared/models/user.model";
import {FormattedResponse} from "../../shared/models/formatted-response";
import {SidebarRoute} from "../../shared/models/sidebar-routes.model";
import {UtilitiesService} from "../../shared/services/utilities.service";

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  host: { ngSkipHydration: 'true' }
})
export class SidebarComponent implements OnInit {
  @Input() isCollapsed: boolean = true;
  user: any = null;
  initials: any = null;

  public routes: SidebarRoute[] = [
    { label: 'Anagrafica', icon: 'database', link: '/app/parking-slots' },
    { label: 'Mappa', icon: 'car', link: '/app/map' },
    { label: 'Gestione utenze', icon: 'user', link: '/app/users', roles: ['amministratore'] }
  ];

  constructor(
    private _auth: AuthService,
    private router: Router,
    private _utilities: UtilitiesService,
  ) { }

  ngOnInit(): void {
    this._auth.me().subscribe({
      next: (response: FormattedResponse<UserModel>) => {
        this.user = response.data;
        this.initials = `${this.user.name.substring(0, 1)}${this.user.surname.substring(0, 1)}`;
      }
    })

    //per illuminare l'elemento della sidebar selezionato
    this.checkCurrentPath(this.router.url);

    this.router.events.subscribe((val) => {
      if(val instanceof NavigationEnd) {
        this.checkCurrentPath(val.url)
      }
    });

    this._utilities.getStallRedirect().subscribe({
      next: () => {
        this.checkCurrentPath(this.router.url);
      }
    })
  }

  /**
   * Al click su Esci
   */
  logout() {
    this._auth.logout().subscribe((res) => {
      this._auth.currentUser.next(null);
      localStorage.clear();
      this.router.navigate(['/auth/login']);
    });
  }

  /**
   * Imposta la proprietà selected a true per la rotta attuale
   * @param url link al quale riporta il bottone
   */
  checkCurrentPath(url: string){
    this.routes.forEach(item => {
      item.selected = item.link.includes(url)
    })
  }

  /**
   * Mostra le sezioni della sidebar in base al ruolo
   * @param route elemento della sidebar
   */
  checkUserRole(route: any) {
    if(route?.roles) return this.user.roles.some((role: any) => route.roles.includes(role.name));
    return true;
  }
}
