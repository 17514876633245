import { Component } from '@angular/core';

interface Route {
  path: string,
  name: string,
  icon: string | null,
  disabled: boolean
}

@Component({
  standalone: false,
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrl: './breadcrumb.component.scss',
  host: { ngSkipHydration: 'true' },
})
export class BreadcrumbComponent {
  routes: Route[] = [
    { path: '/components', name: 'Componenti', icon: null, disabled: true },
    { path: '/components/datatable', name: 'Datatable', icon: null, disabled: false }
  ]
}
