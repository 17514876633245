<section class="sidebar" *ngIf="user">
  <div class="upper-section">
    <article class="p-3">
      <img src="../../../assets/AMTS-logo.svg" width="50%" alt="">
    </article>
    <hr>

    <article class="p-3 user-section">
      <section class="d-flex flex-row justify-content-start align-items-center">
        <div [ngStyle]="{ 'height': '2.5rem', 'width': '2.5rem' }">
          <nz-avatar [nzGap]="4" [ngStyle]="{ 'background-color': '#A1C3E1', 'vertical-align': 'middle' }"
            [nzText]="initials" nzSize="large"></nz-avatar>
        </div>
        <article class="ms-2">
          <span class="text-primary fw-bold">{{user?.name}} {{user?.surname}}</span>
        </article>
      </section>
    </article>

    <article class="p-3">
      <ul nz-menu nzMode="inline" class="" nzTheme="light">
        <ng-container *ngFor="let route of routes">
          <li *ngIf="checkUserRole(route)" nz-menu-item [routerLink]="route.link" [ngClass]="{'selected-list-item': route.selected}">
            <span nz-icon [nzType]="route.icon!"></span>
            <span>{{ route.label }}</span>
          </li>
        </ng-container>
      </ul>
    </article>
  </div>

  <article class="p-4 sign-out">
    <button nz-button nzType="text" (click)="logout()" class="d-flex align-items-center bg-white">
      <span nz-icon nzType="logout" nzTheme="outline"></span>
      <span>Esci</span>
    </button>
  </article>
</section>
