import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { NgZorroAntdModule } from './ng-zorro-antd/ng-zorro-antd.module';
import { DatatableComponent } from './datatable/datatable.component';
import { ComponentsRoutingModule } from './components-routing.module';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { BaseComponent } from './base/base.component';
import { ModalComponent } from './modal/modal.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SafePipe } from '../shared/pipes/safe.pipe';

@NgModule({
  imports: [
    CommonModule,
    ComponentsRoutingModule,
    FormsModule,
    NgZorroAntdModule,
    ReactiveFormsModule
  ],
  declarations: [
    BaseComponent,
    BreadcrumbComponent,
    DatatableComponent,
    FooterComponent,
    HeaderComponent,
    ModalComponent,
    SidebarComponent,
    SafePipe
  ],
  exports: [
    NgZorroAntdModule,
    BaseComponent,
    BreadcrumbComponent,
    DatatableComponent,
    FooterComponent,
    FormsModule,
    HeaderComponent,
    ModalComponent,
    ReactiveFormsModule,
    SidebarComponent,
    SafePipe
  ]
})
export class ComponentsModule { }
